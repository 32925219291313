import { useCallback, useState } from "react";

const useVisible = (defaultValue?: boolean) => {
  const [isVisible, setIsVisible] = useState(!!defaultValue);

  const setVisibilityTrue = useCallback(() => setIsVisible(true), []);
  const setVisibilityFalse = useCallback(() => setIsVisible(false), []);
  const visibilityToggle = useCallback(() => setIsVisible((prevState) => !prevState), []);

  return { isVisible, setVisibilityTrue, setVisibilityFalse, visibilityToggle };
};

export default useVisible;
