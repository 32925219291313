import React from "react";
import { useWindowDimensions } from "./useWindowDimensions";

const useTagHeight = () => {
  const tagRef = React.useRef<any>(null);
  const { width: viewportWidth, height: viewportHeight } = useWindowDimensions();
  const [elementHeight, setElementHeight] = React.useState(0);

  React.useEffect(() => {
    const ref = tagRef.current;
    if (!ref) return;

    setElementHeight(ref.clientHeight);
  }, [viewportWidth, viewportHeight]);

  return { elementHeight, tagRef };
};

export default useTagHeight;
